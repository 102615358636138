<template>
    <div class="">
      <label for="" class="editor-label">{{ label }}</label>
      <div class="editor-select-spacing">
        <div class="d-flex align-items-center justify-content-center">
          <input
            type="number"
            class="editor-color-input py-1 mb-2"
            name="padding-top"
            title="Top"
            v-model="internalTop"
          />
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <input
            type="number"
            class="editor-color-input"
            title="Left"
              name="padding-left"
            v-model="internalLeft"
          />
          <div class="blank-space py-3 mx-1 w-100"></div>
          <input
            type="number"
            class="editor-color-input"
             name="padding-right"
            title="Right"
            v-model="internalRight"
          />
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <input
            type="number"
            class="editor-color-input mt-2 py-1"
            title="Bottom"
              name="padding-bottom"
            v-model="internalBottom"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      top: {
        type:[Number,String],
        default: 0
      },
      right: {
        type: [Number,String],
        default: 0
      },
      bottom: {
        type: [Number,String],
        default: 0
      },
      left: {
        type: [Number,String],
        default: 0
      },
      label:{
        type:String,
        default:'Spacing:'
      }
    },
    data() {
      return {
        internalTop: this.top,
        internalRight: this.right,
        internalBottom: this.bottom,
        internalLeft: this.left
      };
    },
    watch: {
      internalTop(newVal) {
        this.$emit('update:top', newVal); // sync the value of top
      },
      internalRight(newVal) {
        this.$emit('update:right', newVal); // sync the value of right
      },
      internalBottom(newVal) {
        this.$emit('update:bottom', newVal); // sync the value of bottom
      },
      internalLeft(newVal) {
        this.$emit('update:left', newVal); // sync the value of left
      },
      // Watch for changes from the parent and update the internal state
      top(newVal) {
        this.internalTop = newVal;
      },
      right(newVal) {
        this.internalRight = newVal;
      },
      bottom(newVal) {
        this.internalBottom = newVal;
      },
      left(newVal) {
        this.internalLeft = newVal;
      }
    }
  };
  </script>
  

<style scoped>
.editor-select-spacing{
    border-radius: 6px;
   
background: #F7F7F7;
padding: 6px;
gap:8px;
}
.editor-color-input{
    outline: none;
    border:1px solid transparent;
    background: transparent;
    width: 20%;
    text-align: center;
    color: #D2D8E0;
}
.editor-color-input:hover{
    border-radius: var(--radius-sm, 6px);
border: 1px solid #D2D8E0;
background: #FFF;
color: black;
}
.editor-color-input:focus{
    border-radius: var(--radius-sm, 6px);
    box-shadow: 0px 0px 1px 1px var(--secondary-color);
    background: #FFF;
    color: black;
}
.blank-space{
   
    border-radius: 3px;
background: #FFF;
}
</style>